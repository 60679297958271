import styled from 'styled-components';

export const StyledKitchenSinkActions = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  border: 1px solid ${props => props.theme.primary};
  background-color: ${props => props.theme.secondary};
  max-width: 150px;
  text-align: center;

  button {
    display: inline-block;
    color: ${props => props.theme.primary};
  }
`;
