import React from 'react';
import { Helmet } from 'react-helmet';
import { Widgets } from '../widgets';
import { Main } from '../parts/main';
import { graphql } from 'gatsby';
import { PageSlice } from '../../../gatsby-node';
import { KitchenSinkPageQueryQuery } from '../../../types/gatsby-types';
import { useLayoutTheme } from '../layout/layout';
import { getRandomArrayItem, filterNotEmpty } from '../../helpers/arrayHelpers';
import { shuffle } from 'lodash';
import { useEventCallback } from '../../helpers/hooks';
import { KitchenSinkActions } from '../kitchen-sink/kitchen-sink-actions';
import { useSiteMetaContext } from '../site-meta';
import { ThemeProvider } from 'styled-components';
import { ThemeName, selectTheme } from '../../helpers/theme-helpers';

/**
 * Come from Gatsby, see gatsby-node.js
 */
export interface PageTemplateProps {
  data: KitchenSinkPageQueryQuery;
  pageContext: PageSlice;
}

export const KitchenSinkPageFragment = graphql`
  fragment KitchenSinkPageFragment on Nmbl_KitchenSinkPage {
    contentItemId
    displayText
    marqueeWidgets {
      contentItems {
        ...WidgetFragment
      }
    }
    otherWidgets {
      contentItems {
        ...WidgetFragment
      }
    }
  }
`;

const toggleTheme = (theme: string) => (theme === 'light' ? 'dark' : 'light');

const KitchenSinkPageTemplate: React.FC<PageTemplateProps> = ({ data }) => {
  const page = data.nmbl.kitchenSinkPage && data.nmbl.kitchenSinkPage[0];

  if (!page) {
    throw new Error('Error loading page.');
  }

  const marqueeWidgets = React.useMemo(
    () =>
      page.marqueeWidgets?.contentItems
        ? [...page.marqueeWidgets.contentItems]
        : [],
    [page.marqueeWidgets]
  );

  const [headerTheme, setLocalHeaderTheme] = React.useState<ThemeName>('light');
  const [footerTheme, setLocalFooterTheme] = React.useState('dark');

  const [marqueeWidget, setMarqueeWidget] = React.useState(
    (marqueeWidgets && marqueeWidgets[0]) || null
  );
  const [nonMarqueeWidgets, setNonMarqueeWidgets] = React.useState(
    page.otherWidgets?.contentItems ?? []
  );

  const { setHeaderTheme, setFooterTheme } = useSiteMetaContext();

  /**
   * Change themes on toggle
   */
  React.useEffect(() => {
    setHeaderTheme(headerTheme, true);
    setFooterTheme(footerTheme);
  }, [headerTheme, footerTheme, setHeaderTheme, setFooterTheme]);

  const toggleHeaderTheme = useEventCallback(() => {
    console.log(
      'toggling header theme: ',
      headerTheme,
      toggleTheme(headerTheme)
    );
    setLocalHeaderTheme(toggleTheme(headerTheme));
  }, [headerTheme]);
  const toggleFooterTheme = useEventCallback(
    () => setLocalFooterTheme(toggleTheme(footerTheme)),
    [footerTheme]
  );
  const cycleMarquee = useEventCallback(
    () => setMarqueeWidget(getRandomArrayItem(marqueeWidgets) ?? null),
    [marqueeWidgets]
  );
  const shuffleWidgets = useEventCallback(
    () => setNonMarqueeWidgets(shuffle(page.otherWidgets?.contentItems ?? [])),
    [page.otherWidgets?.contentItems]
  );
  const allWidgets = React.useMemo(
    () => ({
      contentItems: [marqueeWidget, ...nonMarqueeWidgets].filter(
        filterNotEmpty
      ),
    }),
    [marqueeWidget, nonMarqueeWidgets]
  );

  console.log('current header theme: ', headerTheme);

  useLayoutTheme(headerTheme, footerTheme);

  return (
    <Main template="kitchen-sink-page">
      <Helmet>
        <title>{page.displayText}</title>
      </Helmet>
      <Widgets widgets={allWidgets.contentItems} />
      <ThemeProvider theme={selectTheme(footerTheme)}>
        <KitchenSinkActions
          onToggleHeaderTheme={toggleHeaderTheme}
          onToggleFooterTheme={toggleFooterTheme}
          onCycleMarquee={cycleMarquee}
          onShuffleWidgets={shuffleWidgets}
        />
      </ThemeProvider>
    </Main>
  );
};
export default KitchenSinkPageTemplate;

export const pageQuery = graphql`
  query KitchenSinkPageQuery {
    nmbl {
      kitchenSinkPage(first: 1) {
        ...KitchenSinkPageFragment
      }
    }
  }
`;
