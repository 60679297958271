import * as React from 'react';
import { StyledKitchenSinkActions } from './kitchen-sink-actions.styles';

export interface KitchenSinkActionsProps {
  onToggleHeaderTheme: () => void;
  onToggleFooterTheme: () => void;
  onCycleMarquee: () => void;
  onShuffleWidgets: () => void;
}

export const KitchenSinkActions: React.FC<KitchenSinkActionsProps> = props => (
  <StyledKitchenSinkActions>
    <button onClick={props.onToggleHeaderTheme}>Toggle Header Theme</button>
    <button onClick={props.onToggleFooterTheme}>Toggle Footer Theme</button>
    <button onClick={props.onCycleMarquee}>Cycle Marquee</button>
    <button onClick={props.onShuffleWidgets}>Shuffle Widgets</button>
  </StyledKitchenSinkActions>
);
